
const StoreUserData = (user, isLogin) =>  {
localStorage.setItem('id', user.id)
localStorage.setItem('firstName', user.firstName)
localStorage.setItem('lastName', user.lastName)
localStorage.setItem('gender', user.gender)
localStorage.setItem('phone', user.phone)
localStorage.setItem('email', user.email)
localStorage.setItem('token', user.token)
localStorage.setItem('isLogin', isLogin)
}

export default StoreUserData;