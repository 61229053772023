import { useMemo, useState } from 'react';
import {MdOutlineCancel} from 'react-icons/md'
import * as crud from "./../api/Crud";
import './AddNote.css'

function AddNote({refreshNote, notId,setAddNote,setNoteId, action, setAction}) {
    const [noteForm, setNoteForm] = useState({
        userId: localStorage.getItem('id'),
        subject: "",
        detail: "",
        themeColor: "#808080",
      });

      const [res, setRes] = useState({
        status: "",
        message: "",
      });
  
      const onchange = (e) => {
        setNoteForm({ ...noteForm, [e.target.name]: e.target.value });
      };

  
       useMemo(()=>{
        if(notId != ''){
        crud
        .getCrudById("notes/" + notId)
        .then((res) => {
          setNoteForm({
            subject: res.data[0].subject,
            detail: res.data[0].detail,
            themeColor: res.data[0].themeColor,
          });
        })
        .catch((err) => {});
       }},[])
     

     function resetForm () {
        setNoteId('');
        setNoteForm({
            subject: '',
            detail: '',
            themeColor: '#808080',
          });
     }

      const noteSubmit = (e) => {
        e.preventDefault();
        if(localStorage.getItem('id') == null){
  alert('Create account first!')
        }
      else if(noteForm.subject != ''){
        if(notId!==''){
          crud.editCrud("notes/" + notId, noteForm).then((res) => {
            alert(res.data.message);
            setAddNote(false);
            setRes({
              status: res.data.status,
              message: res.data.message,
            });
          }
          
          );
          setAction('Add New Note')
        }
        else {
          crud.addCrud("notes", noteForm).then((res) => {
            alert(res.data.message);
            setRes({
              status: res.data.status,
              message: res.data.message,
            });
          }
          
          
          );
        }
       setInterval(() => {
        refreshNote();
       }, 1000);
        resetForm();
    }
    else {
        alert ('Please enter valid note data!')
    }
      };
    
      
    return ( 
        <div className='add-note-container'>
                <div className= "add-note">
          <h3>
          <span className='add-note-cancel'>
            <MdOutlineCancel onClick={() => {setAddNote(false); resetForm()}} />
          </span>
          <span>{action }</span>
          </h3>
         
          <div>
            <form className="note-form" onSubmit={noteSubmit}>
              {res.status != "" ? (
                <h4 className={res.status == "success" ? "success" : "err"}>
                  {res.message}
                </h4>
              ) : (
                ""
              )}
              <div className="add-note-form">
                <label htmlFor="">Subject</label>
                <input
                  type="text"
                  name="subject"
                  className="input"
                  onChange={(e) => onchange(e)}
                  value={noteForm.subject}
                />
              </div>
              <div className="add-note-form">
                <label htmlFor="">Detail</label>
                <textarea
                  name="detail"
                  id=""
                  cols="30"
                  className="input"
                  rows="6"
                  onChange={(e) => onchange(e)}
                  value={noteForm.detail}
                ></textarea>
              </div>

              <div>
                <label>Theme Color</label>
                <input
                  type="color"
                  name="themeColor"
                  value={noteForm.themeColor}
                  onChange={(e) => onchange(e)}
                />
              </div>
              <button>Save</button>
            </form>
          </div>
        </div>
        </div>
     );
}

export default AddNote;