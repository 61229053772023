import * as crud from "./../api/Crud";
import "./AddTodo.css";
import { useState, useMemo } from "react";
import { MdOutlineCancel } from "react-icons/md";

function AddTodo({ setAddTodo, action, todoId, refresh, setTodoId }) {
  const [todoForm, setTodoForm] = useState({
    userId: localStorage.getItem("id"),
    title: "",
    description: "",
    progress: "New",
  });
  const [res, setRes] = useState({
    status: "",
    message: "",
  });

  useMemo(() => {
    if (todoId != "") {
      crud
        .getCrudById("todos/" + todoId)
        .then((res) => {
          setTodoForm({
            title: res.data[0].title,
            description: res.data[0].description,
            progress: res.data[0].progress,
          });
        })
        .catch((err) => {});
    }
  }, []);

  const onChange = (e) => {
    setTodoForm({ ...todoForm, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (localStorage.getItem("id") == null) {
      alert("Create account first!");
    } else if (todoForm.title != "") {
      if (todoId != "") {
        crud.editCrud("todos/" + todoId, todoForm).then((res) => {
          setRes({
            status: res.data.status,
            message: res.data.message,
          });
          if (res.data.status == "success") {
            refresh();
          }
        });
      } else {
        crud.addCrud("todos", todoForm).then((res) => {
          setRes({
            status: res.data.status,
            message: res.data.message,
          });
          if (res.data.status == "success") {
            refresh();
            resetForm();
          }
        });
      }
    } else {
      alert("Enter valid data!");
    }
  };

  function resetForm() {
    setTodoId("");
    setTodoForm({
      title: "",
      description: "",
      progress: "",
    });
  }

  return (
    <div className="add-todo-container">
      <div className="add-todo-main">
        <h3>
          <span className="add-note-cancel">
            <MdOutlineCancel
              onClick={() => {
                setAddTodo(false);
                resetForm();
              }}
            />
          </span>
          <span> {action}</span>
        </h3>
        <form action="" className="addo-todo-form" onSubmit={onSubmit}>
          {res != "" ? (
            <h4
              className={res.status == "success" ? "todo-success" : "todo-err"}
            >
              {res.message}
            </h4>
          ) : (
            ""
          )}
          <div className="todo-form-item">
            <label htmlFor="">Title</label>
            <input
              type="text"
              name="title"
              value={todoForm.title}
              className="form-input"
              onChange={(e) => onChange(e)}
            />
          </div>
          <div className="todo-form-item">
            <label htmlFor="">Description</label>
            <textarea
              name="description"
              value={todoForm.description}
              className="form-input"
              cols="30"
              rows="5"
              onChange={(e) => onChange(e)}
            ></textarea>
          </div>
          <div className="todo-form-item">
            <label htmlFor="">Progress</label>
            <select
              className="todo-progress form-input"
              value={todoForm.progress}
              name="progress"
              onChange={(e) => onChange(e)}
            >
              <option value="New">New</option>
              <option value="In Progress">In Progress</option>
              <option value="Issue">Issue</option>
              <option value="On Hold">On Hold</option>
              <option value="Rejected">Rejected</option>
              <option value="Done">Done</option>
            </select>
          </div>
          <button>Save</button>
        </form>
      </div>
    </div>
  );
}

export default AddTodo;
