import { useState } from 'react';
import {useParams} from 'react-router-dom'
import { addCrud } from "./../api/Crud";
import './AddLookup.css'

function AddLookup() {
    const [lookupForm, setLookupForm] = useState(
        {
             area :  '' ,
             type :  '' ,
             value :  '' ,
             status :  '' ,
             createdBy :  '' ,
             updatedBy :  '' 
          }
    );
    let {id} = useParams();

    const onChange = (e) =>{
       setLookupForm(prev=> ({...prev, [e.target.name]:e.target.value}))
    }

const onSubmit = (e) => {
e.preventDefault();
addCrud("/lookups", lookupForm)
      .then((res) => {
       console.log(res)
      })
      .catch((err) => {
        console.log(err);
      });

}

    return ( 
        <div>
            <h3>Add Look up</h3>
           <form onSubmit={onSubmit} className = 'add-lookup-form'>
            <div className='lookup-item'>
                <label htmlFor="">Area</label>
                <input type="text" name='area'   onChange = {(e) =>onChange(e)} />
            </div>
            <div className='lookup-item'>
                <label htmlFor="">Type</label>
                <input type="text" name='type'   onChange = {(e) =>onChange(e)} />
            </div>
            <div className='lookup-item'>
                <label htmlFor="">Value</label>
                <input type="text" name='value'   onChange = {(e) =>onChange(e)} />
            </div>
            <div className='lookup-item'>
                <label htmlFor="">Statue</label>
                <input type="text" name='status'   onChange = {(e) =>onChange(e)} />
            </div>
            <div>
                <button type="submit">Submit</button>
            </div>
           </form>
        </div>
     );
}

export default AddLookup;