import {
  AiOutlineEdit,
  AiOutlinePlus,
  AiOutlineCheckCircle,
} from "react-icons/ai";
import { MdOutlineDelete } from "react-icons/md";
import "./Todo.css";
import AddTodo from "./AddTodo";
import { useState } from "react";
import Modal from "./Modal";
import * as crud from "./../api/Crud";
import ConfirmDelete from "./ConfirmDelete";
import { useEffect } from "react";
import moment from "moment";
function Todo() {
  const [addTodo, setAddTodo] = useState(false);
  const [todoId, setTodoId] = useState("");
  const [isConfirmDelete, setConfirmDelete] = useState(false);
  const [action, setAction] = useState("Add New Todo");
  const [todos, setTodos] = useState([]);
  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
    refresh();
  }, []);

  function refresh() {
    crud
      .getCrudList(`todos?searchKey=${searchKey}&userId=${localStorage.getItem('id')}`)
      .then((res) => {
        setTodos(res.data);
      })
      .catch((err) => {});
  }

  function deleteSuccessed() {
    refresh();
  }

  function confirmDeletePop(id) {
    setConfirmDelete(!isConfirmDelete);
    setTodoId(id);
  }

  return (
    <div className="todo-container">
      <div>
        <h3>Todos</h3>
        <div>
          <button
            className="add-todo"
            onClick={() => {
              setAddTodo(!addTodo);
              setAction("Add New Todod");
            }}
          >
            {" "}
            <AiOutlinePlus /> Todo
          </button>
          <div className="search-todo">
            <input
              type="text"
              className="todo-search"
              onChange={(e) => {
                setSearchKey(e.target.value);
                refresh();
              }}
            />
            <button className="todo-search-button" onClick={() => refresh()}>
              Search
            </button>
          </div>
        </div>
      </div>
      <div className="todo-list">
        <ul>
          {todos.map((todo, index) => (
            <li key={index}>
              <label htmlFor="" className="list-action">
                <AiOutlineEdit
                  className="edit"
                  onClick={() => {
                    setAddTodo(!addTodo);
                    setTodoId(todo.id);
                    setAction("Edit Todod");
                  }}
                />
                <MdOutlineDelete
                  className="delete"
                  onClick={() => confirmDeletePop(todo.id)}
                />
              </label>

              <p className="todo-lis">
                <AiOutlineCheckCircle className="check-icon" />
                <div>
                  <h3>{todo.title}</h3>
                  <p className="description">{todo.description}</p>
                  <span className="date">
                    {todos.length > 0
                      ? moment(todo.createdDate).format("LLLL")
                      : ""}
                  </span>
                  <br />
                  {todo.createdDate !== todo.updatedDate ? (
                    <span className="date">
                      {todos.length > 0
                        ? moment(todo.updatedDate).format("LLLL")
                        : ""}
                    </span>
                  ) : (
                    ""
                  )}
                  <div className="todo-progress">
                    <span>Status: {todo.progress}</span>
                  </div>
                </div>
              </p>
            </li>
          ))}
          {todos.length < 1 ? 
            searchKey != "" ? 
              <p className="not-found">
                Todo is not available on search key '{searchKey}' !
              </p>
             : 
              <p className="not-found">
                No todos found yet. Add your private todo{" "}
                <u
                  className="add-here"
                  onClick={() => {
                    setAddTodo(!addTodo);
                    setAction("Add New Todod");
                  }}
                >
                  here
                </u>{" "}
                !
              </p>
            
           : 
            ""}
        </ul>
      </div>
      {addTodo ? (
        <AddTodo
          setAddTodo={setAddTodo}
          action={action}
          todoId={todoId}
          refresh={refresh}
          setTodoId={setTodoId}
        />
      ) : (
        ""
      )}
      {/*   {addTodo? <Modal  setAddTodo = {setAddTodo}> <AddTodo /> </Modal> : ''} */}
      {isConfirmDelete ? (
        <ConfirmDelete
          url={"todos"}
          id={todoId}
          confirmDeletePop={confirmDeletePop}
          deleteSuccessed={deleteSuccessed}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Todo;
