import "./LookUp.css";
import { useState, useEffect } from "react";
import { getCrudList } from "./../api/Crud";
import { AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import { MdOutlineDelete } from "react-icons/md";
import {Link} from 'react-router-dom';
import axios from "axios";
function Lookup() {
  const [lookups, setLookups] = useState([]);
   const [countries, setCountry] = useState([])
   const [states, setState] = useState([])
   const [cities, setCity] = useState([])
  useEffect(() => {
    getCrudList("/lookups")
      .then((res) => {
        setLookups(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(()=> {
    axios.get('https://geodata.phplift.net/api/index.php?type=getCountries').then(res=> {
      setCountry(res.data.result);
    })
 
  })

  const countryChange =(e) => {
console.log(e.target.value)
axios.get('https://geodata.phplift.net/api/index.php?type=getStates&countryId=' +e.target.value).then(res=> {
  console.log(res)
  setState(res.data.result);
})

  }

  const stateChange =(e) => {
   
    axios.get('https://geodata.phplift.net/api/index.php?type=getCities&countryId=&stateId=' +e.target.value).then(res=> {
      console.log(res)
      setCity(res.data.result);
    })
    
      }

  return (
    <div className="lookup-container">
      <div className="list-title">
        <h3>Lookups</h3>
      </div>
      <div className="lookup-mani">
        <div className="look-access">
          <div></div>
          <button> <Link to = '/admin/add-lookup/new'>
            <span className="link-span">
            <AiOutlinePlus /> Add Lookup
            </span>
            </Link></button>
        </div>
       <div className="lookup-table">
       <table border>
          <thead>
            <tr>
              <th>#</th>
              <th>Area</th>
              <th>Type</th>
              <th>Value</th>
              <th>Status</th>
              <th>Created Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {lookups.map((lookup, index) => (
              <tr key={lookup.id}>
                <td>{index + 1}</td>
                <td>{lookup.area}</td>
                <td>{lookup.type}</td>
                <td>{lookup.value}</td>
                <td>{lookup.status}</td>
                <td>{lookup.createdDate}</td>
                <td>
                  <button className="button">
                   <Link to = {'/admin/add-lookup/' + lookup.id}>
                    <span className="link-span"><AiOutlineEdit /> Edit</span>
                   </Link>
                  </button>
                  <button className="delete-bnt">
                    <MdOutlineDelete /> Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      
        <select onChange={(e)=>countryChange(e)}>
           {countries.map(country=><option value={country.id} label={country.name}></option>)}
        
        </select>
        <select onChange={(e)=>stateChange(e)}>
           {states.map(state=><option value={state.id} label={state.name}></option>)}
        
        </select>
        <select onChange={(e)=>stateChange(e)}>
           {cities.map(city=><option value={city.id} label={city.name}></option>)}
        
        </select>
       </div>
      </div>
      <div>
     
      </div>
    </div>
  );
}

export default Lookup;
