import { Axios } from "./AxiosAPI";

export const addCrud = (appendUrl, data) => {
    return  Axios.post(appendUrl, data);
} 

export const getCrudList = (appendUrl) => {
 return  Axios.get(appendUrl)
} 


export const getCrudById = (appendUrl, data) => {
    return  Axios.get(appendUrl)
} 

export const editCrud = (appendUrl, data) => {
    return  Axios.patch(appendUrl, data);
} 


export const deleteCrud = (appendUrl) => {
    return  Axios.delete(appendUrl)
} 

