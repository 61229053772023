
import './Admin.css'
import {Link , Outlet} from 'react-router-dom'
import {AiOutlineDashboard} from 'react-icons/ai';
import {FiUsers} from 'react-icons/fi';
import {IoLayersOutline} from 'react-icons/io5'

function Admin() {
    return ( 
        <div className="admin-container">
            <div className="admin-header">
                <div>
                <a href="#" id = 'admin-logo'>Avola ICT Portal</a>
                </div>
            </div>
            <div className='admin-content'>
                <div className="admin-side-bar">
                    <p className='admin'>Portal Admin</p>
                    <ul>
                    <li>
                            <Link to = "lookup"><AiOutlineDashboard /> Dashboard</Link>
                        </li>
                        <li>
                            <Link to = "lookup"><IoLayersOutline /> Look ups</Link>
                        </li>
                        <li>
                            <Link to = "lookup"> <FiUsers />  Users</Link>
                        </li>
                    </ul>
                </div>
                <div className="admin-main">
                    <Outlet />
                </div>
            </div>
        </div>
     );
}

export default Admin;