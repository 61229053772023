import {AiOutlineWarning} from 'react-icons/ai'
import * as crud from "./../api/Crud";
import './ConfirmDelete.css'
function ConfirmDelete({url,id,confirmDeletePop, deleteSuccessed}) {
    const okDelete = () => {
       if(id!=='' && url !== ''){
      crud.deleteCrud(url + '/' +id).then((res) => {
        if(res.data.status == 'success'){
            confirmDeletePop('');
            alert(res.data.message);
            deleteSuccessed()
        }
      })
      .catch((err) => {});
       }
    }
    return ( 
        <div className='delete-container'>
            <div className='confirm-delete' >
                <h3><AiOutlineWarning className='warning' /> Are you sure to delete this record?</h3>
                <p className='delete-info'>The action is not reversed.</p>
                <div className='delete-action'>
                    <button className='cancel' onClick={()=>confirmDeletePop('')}>Cancel</button>
                    <button className='ok' onClick={okDelete}>Ok</button>
                </div>
            </div>
        </div>
     );
}

export default ConfirmDelete;