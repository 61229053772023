import React from 'react';
import './Home.css'
const Home = () => {

    return (
        <div className='main'>
       <div className="content">
       <div className="image">
        <h2>Welcome to Avola ICT Portal.</h2>
       </div>
      </div>

      <aside>
        <div className="search">
          <input type="text" placeholder="Search..." />
          <button>Search</button>
        </div>
        <div className="recommended-tech">
          <h5 className="title">Recommened Technology</h5>
          <ul>
            <li> <a href=""> React is the recent technology library and it is robust technology. </a></li>
            <li><a href=""> React is the recent technology library </a> </li>
            <li><a href=""> React is the recent technology library </a> </li>
            <li><a href=""> React is the recent technology library </a> </li>
            <li><a href=""> React is the recent technology library </a> </li>
          </ul>
        </div>
      </aside>
        </div>
    );

}

export default Home;
