import { useState, useEffect } from "react";
import * as crud from "./../api/Crud";
import {
  AiOutlineEdit,
  AiOutlinePlus,
  AiOutlineCheckCircle,
} from "react-icons/ai";
import { MdOutlineDelete } from "react-icons/md";
import moment from 'moment'
import "./Note.css";
import ConfirmDelete from './ConfirmDelete';
import AddNote from './AddNote'

function Note() {
  const [addNote, setAddNote] = useState(false);
  const [action, setAction] = useState('Add New Note')
  const [notes, setNotes] = useState([]);
  const [note, setNote] = useState({});
const [isDeleteLoading, setIsDeleteLoading] = useState(false);
const [notId, setNoteId] = useState('');
const [searchKey,setSearchKey] = useState('');
  useEffect(() => {
    refreshNote();
  }, []);

  const itemDetail = (id) => {
    setNote(notes.filter((item) => item.id == id)[0]);
  };
function confirmDeletePop(id) {
  setIsDeleteLoading(!isDeleteLoading);
 setNoteId(id) 
}

function refreshNote () {
  crud
  .getCrudList(`notes?searchKey=${searchKey}&userId=${localStorage.getItem('id')}`)
  .then((res) => {
   if(res.data.length>0){
    setNote(res.data[0]);
    setNotes(res.data);
   }
   else{
    setNotes([]);
   }
  })
  .catch((err) => {});

}

const search = (value) => {
  setSearchKey(value)
  refreshNote()
}
function deleteSuccessed() {
refreshNote();
}

const getNoteDataById = (id) => {
   setNoteId(id);
   setAddNote(true)
}

  return (
    <div className="note-container">
      <div>Notes</div>
      <div className="note-main">
        <div className="note-list">
          <div className="note-action">
            <div className="search-region">
              <input
                className="note-search-input"
                type="text"
                placeholder="search..."
                onChange={(e)=> search(e.target.value)}
              />
              <button className="search-button" onClick={()=> search(searchKey)}>Search</button>
            </div>
            <button
              onClick={() => {
                setAddNote(!addNote);
              }}
            >
              {" "}
              <AiOutlinePlus />
            </button>
          </div>
          <div className="note-item">
            <h4>Note Lists</h4>
            <ul>
              {notes.map((note, index) => (
                <li onClick={() => itemDetail(note.id)} key={index}>
                  <label htmlFor="" className="list-action">
                    <AiOutlineEdit className="edit" onClick={()=>{getNoteDataById(note.id); setAction('Edit Note')}} />
                    <MdOutlineDelete className="delete" onClick={()=>confirmDeletePop(note.id)} />
                  </label>
                  <h5>
                    {" "}
                    <AiOutlineCheckCircle
                      className="check-icon"
                      style={{ color: note.themeColor }}
                    />{" "}
                    {note.subject}
                  </h5>
                </li>
              ))}
              {notes.length < 1 ? (
            searchKey != "" ? (
              <p className="not-found">
                Note is not available on search key '{searchKey}' !
              </p>
            ) : (
              <p className="not-found">
                No notes found yet. Add your private note{" "}
                <u
                  className="add-here"
                  onClick={() => {
                    setAddNote(!addNote);
                    setAction("Add New Note");
                  }}
                >
                  here
                </u>{" "}
                !
              </p>
            )
          ) : (
            ""
          )}
            </ul>
          </div>
        </div>
   
        <div className="note-detail">
          <h3 style={{ backgroundColor: notes.length>0? note.themeColor : '#808080' }}>{notes.length>0?note.subject: ''}</h3>
          <div className="region" style={{  backgroundColor: notes.length>0? note.themeColor : '#808080' }}>
            <p>{notes.length>0? note.detail : 'Note is empty, add your private note.'}</p>
            <span className="date">{notes.length>0? moment(note.createdDate).format('LLLL') : ''}</span>
            <br />
            {note.createdDate !== note.updatedDate? <span className="date">{notes.length>0? moment(note.updatedDate).format('LLLL') : ''}</span> : ''}
          </div>
        </div>
      </div>
      {isDeleteLoading? <ConfirmDelete url = {'/notes'} id ={notId} confirmDeletePop = {confirmDeletePop} deleteSuccessed = {deleteSuccessed} /> : ''}
       {addNote ? <AddNote refreshNote = { refreshNote } notId = {notId}  setNoteId = {setNoteId} setAddNote = {setAddNote} action = {action} setAction = {setAction} /> :''}
    </div>
  );
}

export default Note;
