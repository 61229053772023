import Layout from './components/Layout'
import Home from './components/Home'
import Lookup from './components/LookUp';
import Login from './components/accounts/Login'
import CreateUser from './components/accounts/CreateUser';
import Admin from './components/Admin';
import AddLookup from './components/AddLookup';
import Note from './components/Note';
import Todo from './components/Todo';
import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

function App() {
  return (
   <BrowserRouter> 
   <Routes>
    <Route path='admin' element = {<Admin />}>
    <Route path='lookup' element = {<Lookup />} />
    <Route path='add-lookup/:id' element = {<AddLookup />} />
    </Route>
    <Route path='new-user' element = {<CreateUser />}/>
    <Route path='/login' element = {<Login />} />
    
    <Route path='/' element = {<Layout />}>
     <Route index element = {<Home />} />
     <Route  path = '/todo' element = { <Todo />} />
    <Route path= '/note' element = { <Note /> } />
    </Route>
   </Routes>
   </BrowserRouter> 
  );
}

export default App;
