import "./CreateUser.css";
import { Link, Navigate } from 'react-router-dom'
import React, { useState } from "react";
import * as crud from "./../../api/Crud";
import StoreUserData from './StoreUserData';

const CreateUser = () => {
  const [isRegistered,setIsRegistered] = useState(false)
  const [userForm, setUserForm] = useState({
      firstName: '',
      lastName: '',
      birthDate: '',
      gender: '',
      phone: '',
      email: '',
      password: ''
  });
  const onChangeInput = (e) => {
    userForm[e.target.name] = e.target.value;
    setUserForm({...userForm})
  }

  const submitForm = async (e) =>  {
    e.preventDefault();
  await crud.addCrud('users',userForm).then((response)=>{
    if(response.data.status == 'success')  {
      alert(response.data.message)
        crud.addCrud('users/login', {email: userForm.email, password: userForm.password}).then(res => {
          if(res.data.login) {
            StoreUserData(res.data.user, res.data.login)
          }
    })
     
      setIsRegistered(true)
    }
    else {
      alert(response.data.message)
    }
   })
  }
  return (
    <div className="create-user-containner">
      <div className="create-user">
        <h2>Create account.</h2>
        <form className="user-form" onSubmit={submitForm}>
          <div className="user-form-item">
           <label htmlFor="">First Name</label>
            <input  type="text" name="firstName" onChange={onChangeInput} />
          </div>
          <div className="user-form-item">
          <label htmlFor="">Last Name</label>
            <input  type="text" name="lastName" onChange={onChangeInput}/>
          </div>
          <div className="user-form-item">
           <label htmlFor="">Birth Date</label>
            <input  type="date" name="birthDate" onChange={onChangeInput} />
          </div>
          <div className="user-form-item">
          <label htmlFor="">Gender</label>
            <input  type="text" name="gender" onChange={onChangeInput} />
          </div>
          <div className="user-form-item">
           <label htmlFor="">Phone</label>
            <input  type="tel" name="phone" onChange={onChangeInput} />
          </div>
          <div className="user-form-item">
          <label htmlFor="">Email</label>
            <input  type="email" name="email" onChange={onChangeInput} />
          </div>
          <div className="user-form-item">
          <label htmlFor="">Password</label>
            <input  type="Password" name="password" onChange={onChangeInput} />
          </div>
          <button type="submit">Register</button>
          <div className="register-login">
            <h5>Have an account? <Link to = '/login'>Login here.</Link></h5>
          </div>
        </form>
      </div>
       {isRegistered? <Navigate to="/" replace={true} /> : ''}
    </div>
  );
};

export default CreateUser;
