import React from "react";
import "./Layout.css";
import { Outlet, Link } from "react-router-dom";
import { FaPhoneAlt } from 'react-icons/fa';
import { MdLogin } from 'react-icons/md';
import { HiOutlineMail, HiOutlineLocationMarker } from "react-icons/hi";
import { useState } from "react";
const Layout = () => {
  const [isLogin, setIsLogin] = useState(localStorage.getItem('isLogin'));
  // if(isLogin) {
  //   alert('yes')
  // }
  return (
    <div className="body">
      <div className="space">
        <div className="header"></div>
        <div className="main"> </div>
      </div>
      <div className="container">
        <header>
          <div className="header-main">
            <div className="brand">
              <div className="logo">
                <a href="#">Avola ICT Portal</a>
              </div>
              <div className="contact">
               <a href="mailto:"></a>
                <a href="tel:+0929022432"> <FaPhoneAlt className="icon"/> 0929022432</a>
                <a href="mailto:yaschalew10@gmail.com"> <HiOutlineMail className="icon"/> yaschalew10@gmail.com</a>
                <a href="#" className="address">
                    <HiOutlineLocationMarker className="icon"/>
                  Addis Ababa, Ethiopia
                </a>
              </div>
            </div>
            <nav>
              <input type="checkbox" id="check-box" />
              <label htmlFor="check-box" className="clear-bars">
                X
              </label>
              <label htmlFor="check-box" className="bars">
                <div></div>
                <div></div>
                <div></div>
              </label>
              <div className="link">
              <ul>
                <li>
                  <Link to={'/'}>Home</Link>
                </li>
                <li>
                  <Link to={'/'}>Tech-Tool</Link>
                </li>
                <li>
                  <Link to={'todo'}>Todos</Link>
                </li>
                <li>
                  <Link to={'note'}>Notes</Link>
                </li>
                <li>
                  <Link to={'/'}>About</Link>
                </li>
                <li>
                  <Link to={'/'}>Contact Us</Link>
                </li>
              </ul>
              {!isLogin? <Link to = 'login'><MdLogin className="icon" /> Login</Link> : 
              <button className="user-prof">{localStorage.getItem('firstName').charAt(0) + localStorage.getItem('lastName').charAt(0) }</button> }
              
              </div>
            </nav>
          </div>
        </header>
        <main>
          <Outlet />
        </main>
        <footer>
          <div className="copywrite">
            <p>
              <a href="https://avolaict.com/" target="_blank">
                &copy;2022- Avola ICT Solutions
              </a>
            </p>
          </div>
        </footer>
      </div>
      <div className="space">
        <div className="right"></div>
        <div className="main"> </div>
      </div>
    </div>
  );
};

export default Layout;
