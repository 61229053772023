
import "./Login.css";
import StoreUserData from './StoreUserData';
import * as crud from './../../api/Crud'
import {FaUserCircle} from 'react-icons/fa'
import { RiLockPasswordFill } from 'react-icons/ri'
import { HiOutlineMail } from "react-icons/hi";
import {Link, Navigate} from 'react-router-dom'
import { useState,React } from "react";

const Login = () => {
  const [loginForm, setLoginForm] = useState ({
    email: '',
    password: ''
  })

  const [isLogin, setIsLogin] = useState(false)

  const onChange = (e) => {
    setLoginForm({...loginForm, [e.target.name] : e.target.value})
  }

  const onSubmit = (e) => {
  e.preventDefault();
  crud.addCrud('users/login', loginForm).then(res => {
    if(res.data.login) {
      alert(res.data.message)
      StoreUserData(res.data.user, res.data.login)
      setIsLogin(true)
    }
    else {
      alert(res.data.message)
    }
})

  }

  return (
    <div className="login-containner">
      <div className="login">
        <h2 className="login-title"><FaUserCircle /></h2>
        <h3>Welcome, Login to your account.</h3>
        <form onSubmit={onSubmit}>
          <div className="form-item">
            <span className="icons"><HiOutlineMail /></span>
            <input className="input" type="email" name="email" onChange={(e) => onChange(e)} />
          </div>
          <div className="form-item">
          <span className="icons"><RiLockPasswordFill /></span>
            <input className="input" type="Password" name="password" onChange={(e) => onChange(e)} />
          </div>
          <div className="remember-forgot">
            <div>
              <label htmlFor="">
                {" "}
                <input type="checkbox" /> Remember me
              </label>
            </div>
            <div>
              <a href="http://">Forgot password</a>
            </div>
          </div>
          <button>Login</button>
          <div className="register">
            <h5>No account yet? <Link to = '/new-user'>Register here.</Link></h5>
          </div>
        </form>
      </div>
      {isLogin? <Navigate to="/" replace={true} /> : ''}
    </div>
  );
};

export default Login;
